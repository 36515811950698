import React, {useState} from 'react';
import DataViews from './DataViews';
import './MovieDatabase.css';

function MovieDatabase() {

    const [view, setView] = useState('movielist');
    const [search, setSearch] = useState('');

    function validateInput(input) {
      if (input.match(/^[0-9a-zA-Z]+$/)) {
        return true;
      } else {
        return false;
      }
    }

    function searchMovies() {
        let searchWord = document.getElementById('inputSearch').value;
        let errorSpan = document.getElementById('validationError');
        let inputValid = validateInput(searchWord);
        if (inputValid) {
          setSearch(searchWord);
          setView('moviesearch');
          errorSpan.innerHTML = '';
        } else {
          errorSpan.innerHTML = 'Error: Please enter only letters and numbers';
        }
    }

    function searchPeople() {
      let searchWord = document.getElementById('inputSearch').value;
      let errorSpan = document.getElementById('validationError');
      let inputValid = validateInput(searchWord);
        if (inputValid) {
          setSearch(searchWord);
          setView('personsearch');
          errorSpan.innerHTML = '';
        } else {
          errorSpan.innerHTML = 'Error: Please enter only letters and numbers';
        }
  }

    function onSubmit(event) {
      event.preventDefault();
      let searchWhat = document.getElementById('searchWhat').value;
      if (searchWhat === 'movieTitle') {
        searchMovies();
      } else {
        searchPeople();
      }
    }

    return (
      <div className="wrapper">
        <h3>Search Keyword</h3>
        <form id="searchForm" onSubmit={(e) => {onSubmit(e)}}>
          <select name="searchWhat" id="searchWhat">
            <option value="movieTitle">Movie Titles</option>
            <option value="people">Directors/Writers</option>
          </select>
          <input id="inputSearch" type="text" />
          <input className="submitButton" type="submit" value="Submit" />
          <span id="validationError"></span>
        </form>
        <div className="buttonHolder">
          <div className="buttonView" onClick={() => {setView('movielist')}}>
            <p>View All Movies</p>
          </div>
          <div className="buttonView" onClick={() => {setView('personlist')}}>
            <p>View All People</p>
          </div>
        </div>
        <DataViews view={view} viewChanger={setView} data={search} />
      </div>
    );

}

export default MovieDatabase;