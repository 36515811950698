import screenMobile from '../assets/images/mobile-beggars.png';
import screenDesktop from '../assets/images/desktop-beggars.png';


function Questharmonics() {
    return (
        <div>
            <div id="parallaxBanner">
                <div id="bannerText">
                    <h1>Beggars Canyon</h1>
                </div>
            </div>
            <div id="contentWrapper">
                <div className="projectContent">
                    <div style={{textAlign: "center", marginBottom: '3em'}}>
                        <p>Site Link: <a href="https://www.iancomposer.com/beggarscanyon" target="_blank" rel="noreferrer">https://iancomposer.com/beggarscanyon</a></p>
                        <p><strong>Front-End: </strong>React</p>
                        <p><strong>Back-End: </strong>Node.js, Express, MySQL</p>
                    </div>
                    <div className="projectContentRight">
                        <img className="mobileScreenPic" src={screenMobile} alt="Beggars Canyon Screenshot" />
                        <img className="desktopScreenPic" src={screenDesktop} alt="Beggars Canyon Screenshot" />
                    </div>
                    <p>
                        This is the official website of the band Beggars Canyon. Based out of
                        Portland, Oregon, the band has recently released their second album and
                        gaining a loyal following of fans. I am honored to design and implement
                        their website, and hope their listeners enjoy browsing as much as I enjoyed
                        creating it.
                    </p>
                    <p>
                        This website was created with React and utilizes CSS transitions for component
                        entrance/exit animations. The background imagery was taken from artwork on
                        their first album's cover design. The site is fully responsive to all screen
                        sizes and devices.
                    </p>
                    <p>
                        The band is able to update the shows and images displayed on the site by logging
                        into their admin portal. This utilizes an express server that handles user
                        authentication, password hashing, a MySQL database, file uploading to the server,
                        and various GET/POST fetch requests. Everything they modify/upload is updated
                        seamlessly into the website's user interface automatically.
                    </p>
                </div>
                <a className="githubButton" target="_blank" rel="noreferrer" style={{display: 'block', margin: '2em auto 1em auto'}} href="https://github.com/imcmahon87/beggars-canyon">View this site's GitHub repository</a>
            </div>
        </div>
    );
}

export default Questharmonics;